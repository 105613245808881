import React, { useState } from 'react';
import full01 from '../assets/img/gallery/fulls/pstd.png';
import full02 from '../assets/img/gallery/fulls/quiero-recs.png';
import thumb01 from '../assets/img/gallery/thumbs/pstd.png';
import thumb02 from '../assets/img/gallery/thumbs/quiero-recs.png';
import lm from '../assets/img/lm.png';
import pic02 from '../assets/img/yokoFritz.png';
import Layout from '../components/layout';
import Project from '../components/project';

const images01 = [
  {
    id: '1',
    source: full01,
    thumbnail: thumb01,
    caption: 'PSTD',
    description: 'Electron & React',
    landscape: true,
  },
];

const images02 = [
  {
    id: '1',
    source: full02,
    thumbnail: thumb02,
    caption: 'Quiero Recordings',
    description: 'React',
    landscape: true,
  },
];

const year = new Date().getFullYear();

const IndexPage = () => {
  const [status, setStatus] = useState('');

  const submitForm = (ev) => {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        setStatus("SUCCESS");
      } else {
        setStatus("ERROR");
      }
    };
    xhr.send(data);
  }

  return (
    <Layout>
      <section className="intro">
        <header>
          <h1>Hello!<br />I'm Luis</h1>
          <p>

          </p>
          <ul className="actions">
            <li>
              <a href="#first" className="arrow scrolly">
                <span className="label">Next</span>
              </a>
            </li>
          </ul>
        </header>
        <div className="content">
          <div className="profile-content">
            <img className="profile-content__image" src={lm} alt="Luis" data-position="center" />
          </div>
        </div>
      </section>

      <section id="first">
        <header>
          <h2>About me</h2>
        </header>
        <div className="content">
          <p>
            Born, raised and currently living in Guadalajara, Mexico; Self-taught tech-geek and musician. Married my best friend and together,
            we adopted Fritz, a very cool Belgian Malinois Shepherd and Yoko the cutest Boston Terrier.
            Freelancer for over 8 years as well as a team player, working remotely as a Software Engineer at Ergeon (Palo Alto); Websites, web and mobile apps are amongst my list
            of delivered projects that translate into happy clients.
            I'm driven, creative, disciplined and passionate about constructing ideas into reality, always looking for the best option to develop it, even if
            it means I need to learn new and more things to obtain the best solution to each of the challenges presented to me.
            Tend to be more of a listener but when required, I put my points of view on the table up for discussion. ¡Oh! And I'm a “people-person” too.
        </p>
          <span className="image main">
            <img src={pic02} alt="" />
          </span>
        </div>
      </section>

      <section>
        <header>
          <h2>Tool belt</h2>
        </header>
        <div className="content">
          <ul className="feature-icons">
            <li className="icon solid fa-code">JS</li>
            <li className="icon solid fa-code">Node.js</li>
            <li className="icon solid fa-code">React</li>
            <li className="icon solid fa-code">TypeScript</li>
            <li className="icon solid fa-code">MeteorJS</li>
            <li className="icon solid fa-code">D3</li>
            <li className="icon solid fa-code">Serverless Lambdas</li>
            <li className="icon solid fa-code">Sass</li>
            <li className="icon solid fa-code">Electron</li>
            <li className="icon solid fa-code">MongoDB</li>
            <li className="icon solid fa-code">Docker</li>
          </ul>
        </div>
      </section>

      <section>
        <header>
          <h2>Projects</h2>
        </header>
        <div className="content">
          <Project
            title="PSTD"
            description="Electron desktop app to manage your clipboard. Created this app as I missed the UI from a well know osx app, so decided to make my own."
            links={[{
              id: 1,
              href: 'https://github.com/mexin/pstd',
              icon: 'fa-github',
              text: 'git'
            }]}
            images={images01}
          />
          <Project
            title="Quiero Recordings Site"
            description="Website for the record label I started in 2013 with my best friend, This is a refresh I did on Jan 2018"
            links={[{
              id: 2,
              href: 'http://quiero-recordings.com',
              icon: 'fa-desktop',
              text: 'Live'
            }]}
            images={images02}
          />

        </div>
      </section>

      <section>
        <header>
          <h2>Get in touch</h2>
        </header>
        <div className="content">
          <p>
            <strong>Contact me</strong>
          </p>
          <form onSubmit={submitForm}
            action="https://formspree.io/f/xaylzaap"
            method="POST">
            <div className="fields">
              <div className="field half">
                <input type="text" name="name" id="name" placeholder="Name" />
              </div>
              <div className="field half">
                <input type="email" name="email" id="email" placeholder="Email" />
              </div>
              <div className="field">
                <textarea
                  name="message"
                  id="message"
                  placeholder="Message"
                  rows="7"></textarea>
              </div>
            </div>
            <ul className="actions">
              <li>
                {status === "SUCCESS" ? <p>Thanks! Your message has been sent!</p> : <input type="submit" value="Send Message" className="button large primary" />}
                {status === "ERROR" && <p>Ooops! There was an error.</p>}
              </li>
            </ul>
          </form>
        </div>
        <footer>
          <ul className="items">
            <li>
              <h3>Location</h3>
              <span>Guadalajara, México</span>
            </li>
            <li>
              <h3>Social</h3>
              <ul className="icons">
                <li>
                  <a href="https://www.linkedin.com/in/luis-mexin" target="_blank" className="icon brands fa-linkedin-in">
                    <span className="label">LinkedIn</span>
                  </a>
                </li>
                <li>
                  <a href="https://github.com/mexin" target="_blank" className="icon brands fa-github">
                    <span className="label">GitHub</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </footer>
      </section>

      <div className="copyright">
        &copy; Luis Mexin. All rights reserved. {year}
      </div>
    </Layout>
  )
}

export default IndexPage
