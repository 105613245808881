import PropTypes from 'prop-types'
import React from 'react'
import Gallery from '../components/Gallery'
import '../assets/sass/main.scss'

const Project = ({ title, description, links, images }) => (
    <section>
        <header>
            <h3>{title}</h3>
            <p>
                {description}
            </p>
            <ul className="icons">
                {links.map(({
                    id,
                    href,
                    icon,
                    text
                }) => (
                    <li key={id}>
                        <a href={href} target="_blank" className={`icon ${text === 'git'? 'brands' : 'solid'} ${icon}`}>
                            <span className="label">{text}</span>
                        </a>
                    </li>
                ))}
            </ul>
        </header>
        <div className="content">
            <Gallery
                images={images.map(
                    ({
                        id,
                        source,
                        thumbnail,
                        caption,
                        description,
                        landscape,
                    }) => ({
                        source,
                        thumbnail,
                        caption,
                        description,
                        landscape,
                    })
                )}
            />
        </div>
    </section>
)

Project.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    links: PropTypes.array,
    images: PropTypes.array,
}

export default Project;
